<template>
	<div id="app">
		<el-form :model="form" label-width="150px" label-position="left">
			<el-form-item label="性别">
				<el-radio-group v-model="form.gender" @change="updateDefaults">
					<el-radio label="MAN">男</el-radio>
					<el-radio label="WOMAN">女</el-radio>
				</el-radio-group>
			</el-form-item>

			<el-form-item label="年龄 (岁)">
				<el-input-number v-model="form.age" :min="0" :max="300" :step="1" placeholder="请输入年龄"></el-input-number>
			</el-form-item>

			<el-form-item label="身高 (cm)">
				<el-input-number v-model="form.heightCm" :min="0" :max="300" :step="1" placeholder="请输入身高"></el-input-number>
			</el-form-item>

			<el-form-item label="体重 (kg)">
				<el-input-number v-model="form.weightKg" :min="0" :max="300" :step="1" placeholder="请输入体重"></el-input-number>
			</el-form-item>

			<el-form-item label="用餐状态">
				<el-radio-group v-model="form.mealStatus">
					<el-radio label="BEFORE_MEAL">餐前</el-radio>
					<el-radio label="AFTER_MEAL">餐后</el-radio>
				</el-radio-group>
			</el-form-item>

			<el-form-item label="用餐时长 (小时)">
				<el-input-number v-model="form.mealTime" :min="0" :max="24" :step="1" placeholder="请输入用餐时长"></el-input-number>
			</el-form-item>
			
			<el-form-item label="有创血糖值 (mg/dL)">
				<el-input-number v-model="form.bloodSugar" :min="0" :max="1000" :step="0.1" placeholder="请输入有创血糖值"></el-input-number>
			</el-form-item>

			<el-form-item label="是否有糖尿病史">
				<el-radio-group v-model="form.hasDiabetes">
					<el-radio label="yes">是</el-radio>
					<el-radio label="no">否</el-radio>
				</el-radio-group>
			</el-form-item>


			<el-form-item>
				<el-button type="primary" @click="scan_code('N01')">扫描二维码</el-button>
			</el-form-item>
		</el-form>

	
	</div>
</template>

<script>
	export default {
		name: 'N01_scanCode',
		data() {
			return {
				form: {
					gender: 'MAN', //性别
					age: '30', //性别
					heightCm: '170', //身高
					weightKg: '70',  //体重
					mealStatus: 'BEFORE_MEAL', //用餐状态
					mealTime: '2', //用餐时长
					hasDiabetes: 'no',// 糖尿病史
					bloodSugar: 7.0  // 有创血糖值
				}
			}
		},
		methods: {
			scan_code(option) {
				// alert(option)
				let data = this.form;
				this.$router.push({
					name: 'scan_code',
					params: {
						option: option,
						data: data
					}
				});
			},
			// 更新身高和体重的默认值
			updateDefaults() {
				if (this.form.gender === 'MAN') {
					this.form.heightCm = 170;
					this.form.weightKg = 70;
				} else if (this.form.gender === 'WOMAN') {
					this.form.heightCm = 160;
					this.form.weightKg = 50;
				}
			}
		},
		watch: {
			// 监听性别变化来自动更新默认值
			'form.gender': 'updateDefaults'
		},
		mounted() {
			this.updateDefaults(); // 页面初始化时设置默认值
		}
	}
</script>

<style scoped>
	#app {
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.el-form {
		text-align: left;
	}
	.el-form-item {
		text-align: left;
	}
	.el-form-item label {
		text-align: left !important;
		white-space: nowrap; /* 防止标签文字换行 */
		overflow: hidden;
		text-overflow: ellipsis; /* 当文字超长时显示省略号 */
	}
	/* 单选框分散对齐 */
	.el-radio-group {
		display: flex;
		justify-content: space-between; /* 分散对齐 */
		align-items: center; /* 确保单选框垂直对齐 */
		width: 100%;
		height: 55px;
	}
</style>